import 'reset-css'
import '@assets/styl/global.styl'

export const onInitialClientRender = () => {
  window.scrollTo(0, 0)
}

export const shouldUpdateScroll = () => false

export const onRouteUpdate = () => {
  window.applyScrollMagic && window.applyScrollMagic()
}

export const onServiceWorkerUpdateReady = () => window.location.reload(true)