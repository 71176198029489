import React from 'react'
import { Link } from '@components'
import cn from 'classnames'
import * as st from '@assets/styl/Button.module.styl'

type ButtonProps = {
  children: string,
  className?: string,
  disabled?: boolean,
  to?: string,
  prefix?: string
}

export default ({ children, className, disabled = false, to, prefix }: ButtonProps) => to ?
  <Link to={to} className={cn(st.core, className)} prefix={prefix}><span className="btn">{children}</span></Link> :
  <button className={cn(st.core, className)} disabled={disabled}><span className="btn">{children}</span></button>