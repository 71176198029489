import React, { useEffect, useState } from 'react'
import { navItems } from '@data'
import { ApiHook, Grid, Link, Translate } from '@components'
import { changeLanguage } from '@components/Translation'
import { graphql, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import * as st from '@assets/styl/Nav.module.styl'

type NavProps = {
  languages?: Object
}

export default ({ languages }: NavProps) => {
  const segments = ApiHook(useStaticQuery(graphql`
    query {
      allSegments {
        nodes {
          name_pt
          name_en
          name_es
          slug_pt
          slug_en
          slug_es
        }
      }
    }
  `).allSegments.nodes, 'segments?max=9999')
  const [menuActive, _setMenuActive] = useState(false)
  const setMenuActive = (value: boolean) => {
    if (value)
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    else
      document.getElementsByTagName('body')[0].style.overflow = null
    _setMenuActive(value)
  }
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 98)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return <div className={cn(st.core, scrolled && st.scrolled)}>
    <Grid wide className={st.grid}>
      <Link to="" className={st.logo}></Link>
      <div className={cn(st.menu, menuActive && st.active)}>
        <button className={st.close} onClick={() => setMenuActive(false)}></button>
        <nav itemScope itemType="http://schema.org/SiteNavigationElement">
          <ul>
            {navItems.map(({ segments: isSegments, className, ...item }, key) => <li key={key}>
              {!isSegments
                ? Translate(item, 'to', to => <Link itemProp="url" to={to} className={className && st[className]} onClick={() => setMenuActive(false)}>
                    <span itemProp="name">{Translate(item, 'name')}</span>
                  </Link>)
                : <div className={st.item}><span>{Translate(item, 'name')}</span><span className="icon-submenu-arrow"></span></div>}
              {isSegments && <div className={st.submenu}><ul>
                {segments.map((segment, key) => <li key={key}>
                  {Translate(segment, 'slug', slug => <Link itemProp="url" to={slug} prefix="segment" onClick={() => setMenuActive(false)}><span itemProp="name">{Translate(segment, 'name')}</span></Link>)}
                </li>)}
              </ul></div>}
            </li>)}
          </ul>
        </nav>
        <div className={st.language}>
          <span className="icon-globe"></span>
          <div className={st.submenu}>
            {changeLanguage(languages, () => setMenuActive(false))}
          </div>
        </div>
      </div>
      <div className={cn(st.hamburger, 'icon-hamburger')} onClick={() => setMenuActive(true)}></div>
    </Grid>
  </div>
}