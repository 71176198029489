// extracted by mini-css-extract-plugin
export var addresses = "Footer-module--addresses--56c3c";
export var bottomGrid = "Footer-module--bottom-grid--1a864";
export var btn = "Footer-module--btn--bc448";
export var contact = "Footer-module--contact--44313";
export var core = "Footer-module--core--340e5";
export var grid = "Footer-module--grid--c4456";
export var lastBlock = "Footer-module--last-block--1d38c";
export var logo = "Footer-module--logo--63ba7";
export var pages = "Footer-module--pages--c9845";
export var phone = "Footer-module--phone--ae75b";
export var segments = "Footer-module--segments--9fbbc";
export var socials = "Footer-module--socials--a5672";
export var tiki = "Footer-module--tiki--bc2cd";