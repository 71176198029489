import React from 'react'
import locale from '@data/locales/footer'
import { navItems } from '@data'
import { ApiHook, Grid, Link, Translate } from '@components'
import { graphql, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import * as st from '@assets/styl/Footer.module.styl'
import logo from '@assets/svg/logo-rodape.svg'
import iso from '@assets/svg/iso.svg'

export default () => {
  const query = useStaticQuery(graphql`
    query {
      allSegments {
        nodes {
          name_pt
          name_en
          name_es
          slug_pt
          slug_en
          slug_es
        }
      }
      allConfigurations {
        nodes {
          facebook
          instagram
          linkedin
          phone_pt
          phone_en
          phone_es
          email
        }
      }
      allAddresses {
        nodes {
          name_pt
          name_en
          name_es
          address_pt
          address_en
          address_es
        }
      }
    }
  `)
  const segments = ApiHook(query.allSegments.nodes, 'segments?max=9999')
  const config = ApiHook(query.allConfigurations.nodes[0], 'configurations')
  const addresses = ApiHook(query.allAddresses.nodes, 'addresses')

  return <footer className={st.core}>
    <Grid wide className={st.grid}>

      <div>
        <img src={logo} width="94" height="61" className={st.logo} />
        <img src={iso} width="186" height="85" />
        <span className={st.contact}>
          <a href={`mailto:${config.email}`}>{config.email}</a><br/>
          {Translate(config, 'phone', phone => <a href={`tel:${phone.replace(/[^0-9\+]/g, '')}`}>{phone}</a>)}
        </span>
      </div>

      <ul className={cn('pin-list', st.addresses)}>
        {addresses.map((address, key) => <li key={key}>
          <b>{Translate(address, 'name')}</b><br/>
          {Translate(address, 'address', address => <span dangerouslySetInnerHTML={{ __html: address.replace(/\n/g, '<br/>') }}></span>)}
        </li>)}
      </ul>

      <ul className={st.segments}>
        <li>{Translate(locale, 'segments')}</li>
        {segments.map((segment, key) => <li key={key}>
          {Translate(segment, 'slug', to => <Link to={to} prefix="segment">{Translate(segment, 'name')}</Link>)}
        </li>)}
      </ul>

      <div className={st.lastBlock}>
        <ul className={st.pages}>
          {navItems.filter(i => i.showOnFooter).map((navItem, key) => <li key={key}>
            {Translate(navItem, 'to', to => <Link to={to}>{Translate(navItem, 'name')}</Link>)}
          </li>)}
        </ul>
        {Translate(locale, 'work_with_us_slug', to => <Link to={to} className={st.btn}>{Translate(locale, 'work_with_us')}</Link>)}
        <span className={st.phone}>{Translate(config, 'phone')}</span>
        <b>{Translate(locale, 'follow')}</b>
        <ul className={st.socials}>
          <li><a href={config.facebook} target="_blank" className="icon-facebook"></a></li>
          <li><a href={config.instagram} target="_blank" className="icon-instagram"></a></li>
          <li><a href={config.linkedin} target="_blank" className="icon-linkedin"></a></li>
        </ul>
      </div>

    </Grid>
    <Grid wide className={st.bottomGrid}><div>
      <span>&copy; 2022 Rotesma</span>
      <span>{Translate(locale, 'privacy')}</span>
      <span>{Translate(locale, 'dev')} Estúdio Alice</span>
      <span><a href="https://www.tiki.com.br" target="_blank" className={st.tiki}>Tiki</a></span>
    </div></Grid>
  </footer>
}