import React, { useRef, useEffect, useState } from 'react'
import { Translate } from '@components'
import locale from '@data/locales/view-more'
import cn from 'classnames'

export default ({ children, classToggle }) => {
  const contentWrapper = useRef<HTMLDivElement>()
  const [open, setOpen] = useState(false)
  const [showBtn, setShowBtn] = useState(false)
  const [style, setStyle] = useState<React.CSSProperties>({})
  useEffect(() => {
    if ((contentWrapper.current.firstChild as HTMLParagraphElement).offsetHeight <= contentWrapper.current.offsetHeight)
      setStyle({ maxHeight: 'none' })
    else
      setShowBtn(true)
  }, [])

  return <li className={cn(open && classToggle)}>
    <div ref={contentWrapper} style={style}>
      <p>{children}</p>
    </div>
    <button onClick={() => {
      setOpen(true)
      setStyle({ transition: 'max-height .2s', maxHeight: (contentWrapper.current.firstChild as HTMLParagraphElement).offsetHeight })
      setTimeout(() => {
        setStyle({ maxHeight: 'none' })
      }, 200)
    }} style={ !showBtn ? { display: 'none' } : undefined }>{Translate(locale, 'button')}</button>
  </li>
}