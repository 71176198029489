import React from 'react'
import cn from 'classnames'
import * as st from '@assets/styl/Grid.module.styl'

type GridProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: React.ReactNode,
  wide?: boolean
}

export default ({ children, className, wide, ...props }: GridProps) => <div className={cn(st.core, className, wide && st.wide)} {...props}>
  {children}
</div>