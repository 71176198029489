exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-ts": () => import("./../../../src/pages/about.ts" /* webpackChunkName: "component---src-pages-about-ts" */),
  "component---src-pages-blog-b-ts": () => import("./../../../src/pages/blog/b/*.ts" /* webpackChunkName: "component---src-pages-blog-b-ts" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-p-jsx": () => import("./../../../src/pages/blog/p/*.jsx" /* webpackChunkName: "component---src-pages-blog-p-jsx" */),
  "component---src-pages-blog-s-ts": () => import("./../../../src/pages/blog/s/*.ts" /* webpackChunkName: "component---src-pages-blog-s-ts" */),
  "component---src-pages-gracias-ts": () => import("./../../../src/pages/gracias.ts" /* webpackChunkName: "component---src-pages-gracias-ts" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-obra-tsx": () => import("./../../../src/pages/obra.tsx" /* webpackChunkName: "component---src-pages-obra-tsx" */),
  "component---src-pages-obrigado-tsx": () => import("./../../../src/pages/obrigado.tsx" /* webpackChunkName: "component---src-pages-obrigado-tsx" */),
  "component---src-pages-orcamento-tsx": () => import("./../../../src/pages/orcamento.tsx" /* webpackChunkName: "component---src-pages-orcamento-tsx" */),
  "component---src-pages-politica-de-privacidad-ts": () => import("./../../../src/pages/politica-de-privacidad.ts" /* webpackChunkName: "component---src-pages-politica-de-privacidad-ts" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-presupuesto-ts": () => import("./../../../src/pages/presupuesto.ts" /* webpackChunkName: "component---src-pages-presupuesto-ts" */),
  "component---src-pages-privacy-policy-ts": () => import("./../../../src/pages/privacy-policy.ts" /* webpackChunkName: "component---src-pages-privacy-policy-ts" */),
  "component---src-pages-request-a-quote-ts": () => import("./../../../src/pages/request-a-quote.ts" /* webpackChunkName: "component---src-pages-request-a-quote-ts" */),
  "component---src-pages-segmento-tsx": () => import("./../../../src/pages/segmento.tsx" /* webpackChunkName: "component---src-pages-segmento-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-pages-technology-ts": () => import("./../../../src/pages/technology.ts" /* webpackChunkName: "component---src-pages-technology-ts" */),
  "component---src-pages-tecnologia-tsx": () => import("./../../../src/pages/tecnologia.tsx" /* webpackChunkName: "component---src-pages-tecnologia-tsx" */),
  "component---src-pages-thank-you-ts": () => import("./../../../src/pages/thank-you.ts" /* webpackChunkName: "component---src-pages-thank-you-ts" */),
  "component---src-pages-trabaja-con-nosotros-ts": () => import("./../../../src/pages/trabaja-con-nosotros.ts" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-ts" */),
  "component---src-pages-trabalhe-conosco-tsx": () => import("./../../../src/pages/trabalhe-conosco.tsx" /* webpackChunkName: "component---src-pages-trabalhe-conosco-tsx" */),
  "component---src-pages-work-with-us-ts": () => import("./../../../src/pages/work-with-us.ts" /* webpackChunkName: "component---src-pages-work-with-us-ts" */)
}

