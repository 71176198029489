import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

type SliderProps = {
  children: React.ReactNode,
  className: string,
  currentSlide?: number,
  dots?: boolean,
  finite?: boolean,
  goToSlide: (s: number) => void,
  lastSlide?: number,
  prepend?: number,
  slidesToScroll?: number
}

function translateX(ref: HTMLDivElement | null, slide: number, offset: number) {
  if (!ref) return `translateX(${slide * -100}%)`
  return `translateX(${-slide * ref.querySelector('li').offsetWidth + offset}px)`
}

export default ({ children, className, currentSlide = 0, dots = false, finite = false, goToSlide, lastSlide: mobileLastSlide = 999, prepend, slidesToScroll = 1 }: SliderProps) => {
  const slideCount = React.Children.count(children)
  const lastSlide = slideCount - slidesToScroll
  const [translate, setTranslate] = useState(0)
  const [transition, setTransition] = useState<React.CSSProperties>({ transition: `transform .5s` })

  useEffect(() => {
    if (currentSlide === translate) return

    if (finite && currentSlide >= slideCount) {
      setTranslate(lastSlide)
      goToSlide(lastSlide)
    } else if (finite && currentSlide < 0) {
      setTranslate(0)
      goToSlide(0)
    } else if (currentSlide >= slideCount) {
      setTranslate(slideCount)
      setTimeout(() => {
        setTransition({})
        setTranslate(0)
        goToSlide(0)
        setTimeout(() => setTransition({ transition: `transform .5s` }), 50)
      }, 500)
    } else if (currentSlide < 0) {
      setTranslate(-slidesToScroll)
      setTimeout(() => {
        setTransition({})
        setTranslate(lastSlide)
        goToSlide(lastSlide)
        setTimeout(() => setTransition({ transition: `transform .5s` }), 50)
      }, 500)
    } else {
      if (currentSlide - lastSlide > 0 && currentSlide - lastSlide < slidesToScroll) {
        setTranslate(lastSlide)
        goToSlide(lastSlide)
      } else if (currentSlide % slidesToScroll) {
        const nextSlide = currentSlide - currentSlide % slidesToScroll + slidesToScroll
        setTranslate(nextSlide)
        goToSlide(nextSlide)
      } else
        setTranslate(currentSlide)
    }
  }, [currentSlide])

  // Carrossel deslizante:
  const [startX, setStartX] = useState(0)
  const [x, setX] = useState(0)
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    const wrapper = ref.current
    const onTouchStart = (e) => {
      const { clientX: x } = e.touches[0]
      setStartX(x)
    }
    wrapper.addEventListener('touchstart', onTouchStart)
    return () => wrapper?.removeEventListener('touchstart', onTouchStart)
  }, [currentSlide])

  useEffect(() => {
    const wrapper = ref.current
    const onTouchMove = (e) => {
      const { clientX: x } = e.touches[0]
      setTransition({})
      setX(x - startX)
    }
    wrapper.addEventListener('touchmove', onTouchMove)
    return () => wrapper?.removeEventListener('touchmove', onTouchMove)
  }, [startX])

  useEffect(() => {
    const wrapper = ref.current
    const onTouchEnd = () => {
      setTransition({ transition: `transform .5s` })
      const threshold = x / wrapper.offsetWidth
      if (threshold <= -0.33 && currentSlide < mobileLastSlide)
        goToSlide(currentSlide + 1)
      else if (threshold >= 0.33)
        goToSlide(currentSlide - 1)
      setX(0)
    }
    wrapper.addEventListener('touchend', onTouchEnd)
    return () => wrapper?.removeEventListener('touchend', onTouchEnd)
  }, [currentSlide, x])
  // =====

  const [slides, setSlides] = useState(
    React.Children.toArray(children).filter((_, index) => index >= slideCount - (prepend || slidesToScroll))
    .concat(React.Children.toArray(children))
    .concat(React.Children.toArray(children).filter((_, index) => index < (prepend || slidesToScroll)))
  )
  useEffect(() =>
    setSlides(React.Children.toArray(children).filter((_, index) => index >= slideCount - (prepend || slidesToScroll))
      .concat(React.Children.toArray(children))
      .concat(React.Children.toArray(children).filter((_, index) => index < (prepend || slidesToScroll)))
    )
  , [prepend, slidesToScroll])

  return <div className={className} ref={ref}>
    <ul>
      {finite ?
        React.Children.map(children, (slide, key) => <li style={{ transform: translateX(ref.current, translate, x), ...transition }} key={key}>{slide}</li>) :
        React.Children.map(slides, (slide, key) => <li style={{ transform: translateX(ref.current, translate + (prepend || slidesToScroll), x), ...transition }} key={key}>{slide}</li>)
      }
    </ul>
    {dots && <div className="dots">
      <ul>
        {Array(slideCount).fill(0).map((_, key) => <li
          key={key}
          className={cn({ active: key === currentSlide || (key === 0 && currentSlide >= slideCount) || (key === slideCount-1 && currentSlide < 0) })}
        >
          <button onClick={() => goToSlide(key)}>{key}</button>
        </li>)}
      </ul>
    </div>}
  </div>
}