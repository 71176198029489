import { useState, useEffect } from 'react'
import requestData from '@api'

export default (defaultValue: any, path: string, filter?: Function) => {
  const [value, setValue] = useState(defaultValue)
  const prop = path.split('?')[0]

  useEffect(() => {
    requestData('get', path)
      .then((data) => setValue(data.result || data[prop]))
      .catch((err) => console.error(err))
  }, [])

  return filter ? filter(value) : value
}