import React, { createContext, useState } from 'react'
import { Loading, Nav } from '@components'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'

export const LoadingContext = createContext(null)
export const LocationContext = createContext(null)

function getLanguages(path: string, props, context) {
  if (path.charAt(path.length - 1) === '/') path = path.slice(0, -1)
  if (path.startsWith('/blog/') && (props['*'] || context.slug)) {
    path = path.split('/blog/')[1]
    if (path.startsWith('p/'))
      return { query: path.split('p/')[1] }
    if (path.startsWith('s/'))
      return { query: path.split('s/')[1] }
    if (path.startsWith('b/'))
      return { query: path.split('b/')[1] }
    return {
      slug_pt: path,
      slug_en: path,
      slug_es: path,
      type: 'blog'
    }
  }
  return context
}

export default ({ children, location, pageContext, ...props }) => {
  // Loading state
  const [visible, setVisible] = useState(false)

  return <LoadingContext.Provider value={{ visible, toggleVisible: (value: boolean) => setVisible(value) }}>
    <LocationContext.Provider value={{ ...location, pathname: process.env.GATSBY_SITE_LANG + location.pathname }}>
      <Loading visible={visible} />
      <Nav languages={getLanguages(location.pathname, props, pageContext)} />
      <TransitionProvider
        location={location}
        mode="immediate"
        enter={{
          opacity: 0
        }}
        usual={{
          opacity: 1
        }}
        leave={{
          opacity: 0,
          config: {
            duration: 200
          }
        }}
      >
        <TransitionViews>
          {children}
        </TransitionViews>
      </TransitionProvider>
    </LocationContext.Provider>
  </LoadingContext.Provider>
}