import React from 'react'
import { Link } from 'gatsby'

export default {
  segments_pt: 'Segmentos',
  segments_en: 'Segments',
  segments_es: 'Áreas',
  follow_pt: 'Siga-nos:',
  follow_en: 'Follow us:',
  follow_es: 'Síguenos:',
  work_with_us_pt: 'Trabalhe conosco',
  work_with_us_en: 'Work with us',
  work_with_us_es: 'Trabaja con nosotros',
  work_with_us_slug_pt: 'trabalhe-conosco',
  work_with_us_slug_en: 'work-with-us',
  work_with_us_slug_es: 'trabaja-con-nosotros',
  privacy_pt: <Link to="/politica-de-privacidade/">Política de privacidade</Link>,
  privacy_en: <Link to="/privacy-policy/">Política de privacidade</Link>,
  privacy_es: <Link to="/politica-de-privacidad/">Política de privacidad</Link>,
  dev_pt: 'Desenvolvido por',
  dev_en: 'Desenvolvido por',
  dev_es: 'Desarrollado por'
}
