import React from 'react'
import links from '@data/locales/links'
import { LocationContext } from '@layouts'

export const getLanguage = (location: Location) =>
location.pathname.startsWith('/en') ? 'en' : 
location.pathname.startsWith('/es') ? 'es' :
'pt'

const languages = ['Português', 'Español', 'English']

const prefixes = {
  segment: ['/segmento/', '/area/', '/segment/'],
  construction: ['/obra/', '/construccion/', '/construction/'],
  blog: ['/blog/', '/blog/', '/blog/']
}
const suffix = '/'

export const changeLanguage = (langObj, onClick) =>
  langObj?.query ?
    <ul>
      <li><a href={`https://www.rotesma.com.br/blog/p/${langObj.query}/`} onClick={onClick}>{languages[0]}</a></li>
      <li><a href={`https://www.rotesma.com.py/blog/b/${langObj.query}/`} onClick={onClick}>{languages[1]}</a></li>
    </ul> :
  langObj?.slug_pt ?
    <ul>
      <li><a href={'https://www.rotesma.com.br' + prefixes[langObj.type][0] + langObj.slug_pt + suffix} onClick={onClick}>{languages[0]}</a></li>
      <li><a href={'https://www.rotesma.com.py' + prefixes[langObj.type][1] + langObj.slug_es + suffix} onClick={onClick}>{languages[1]}</a></li>
    </ul> :
    <LocationContext.Consumer>
      {location => {
        let path = location.pathname
        if (path.slice(-1) !== '/') path += '/'
        return <ul>{links[path] && <>
          <li><a href={'https://www.rotesma.com.br' + links[path][0]} onClick={onClick}>{languages[0]}</a></li>
          <li><a href={'https://www.rotesma.com.py' + links[path][1]} onClick={onClick}>{languages[1]}</a></li>
        </>}</ul>
      }}
    </LocationContext.Consumer>

export default (obj: Object, prop: string, wrapper?: (children: string) => React.ReactNode) => {
  if (!wrapper) wrapper = str => str
  return <LocationContext.Consumer>
    {location => location ? wrapper(obj[`${prop}_${getLanguage(location)}`]) : wrapper(obj[`${prop}_pt`]) }
  </LocationContext.Consumer>
}