// extracted by mini-css-extract-plugin
export var active = "Nav-module--active--89c5f";
export var btn = "Nav-module--btn--8b627";
export var close = "Nav-module--close--7228e";
export var core = "Nav-module--core--ad64f";
export var grid = "Nav-module--grid--67e5d";
export var hamburger = "Nav-module--hamburger--a26da";
export var item = "Nav-module--item--dcda6";
export var language = "Nav-module--language--d25ef";
export var logo = "Nav-module--logo--1f14d";
export var menu = "Nav-module--menu--88cd2";
export var scrolled = "Nav-module--scrolled--cfc81";
export var submenu = "Nav-module--submenu--c0ca3";