import React from 'react'
import { Translate } from '@components'
import parse from 'html-react-parser'
import Helmet from 'react-helmet'
import favicon from '@assets/img/icons/favicon-32x32.png'
import logo from '@assets/img/logo.png'
import opengraph from '@assets/img/opengraph.jpg'
import icon48x48 from '@assets/img/icons/icon-48x48.png'
import icon72x72 from '@assets/img/icons/icon-72x72.png'
import icon96x96 from '@assets/img/icons/icon-96x96.png'
import icon144x144 from '@assets/img/icons/icon-144x144.png'
import icon192x192 from '@assets/img/icons/icon-192x192.png'
import icon256x256 from '@assets/img/icons/icon-256x256.png'
import icon384x384 from '@assets/img/icons/icon-384x384.png'
import icon512x512 from '@assets/img/icons/icon-512x512.png'

type HeadProps = {
  path: string,
  title: string,
  image?: string,
  keywords?: Array<{
    name_pt: string,
    name_en: string,
    name_es: string
  }>,
  yoast_head?: string
}

function fixUrls(str) {
	return str.replace(/(blog\.rotesma\.com\.br\/(([A-ZÀ-Úa-zà-ú0-9-_]*\/)*)(?![\"\']))(?!([A-ZÀ-Úa-zà-ú0-9-_\/]*)\.)/g, `${process.env.GATSBY_URL}/blog/`);
}

function MetaNodes(path, image) {
	return [
		<meta property="og:image" itemProp="image" content={image || `https://${process.env.GATSBY_URL}${opengraph}`} key={0} />,
    <meta name="twitter:image" content={image || `https://${process.env.GATSBY_URL}${opengraph}`} key={1} />,
    <meta charSet="utf-8" key={2} />,
    <meta name="description" content="" key={3} />,
    <meta property="og:title" content="" key={4} />,
    <meta property="og:site_name" content={process.env.SITE_NAME} key={5} />,
    <meta property="og:description" content="" key={6} />,
    <meta property="og:url" content={`https://${process.env.GATSBY_URL}${path}`} key={7} />,
    <meta property="og:locale" content="pt_BR" key={8} />,
    <link rel="icon" type="image/x-icon" href="" key={9} />,
    <script type="application/ld+json" key={10}>
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name": process.env.SITE_NAME,
        "url": `https://${process.env.GATSBY_URL}`,
        "logo": `https://${process.env.GATSBY_URL}${logo}`
      })}
    </script>
	]
}

export default ({ path, title, image, keywords: _keywords, yoast_head }: HeadProps) => {
  if (path === '/') path = ''

  const scriptNodes = []
	const yoastNodes = parse(fixUrls(yoast_head || ''), {
		replace: (node: any) => {
			if (node.type === 'script')
				scriptNodes.push(node)
			if (node.attribs && node.attribs.rel === 'canonical')
				return <></>
		}
	})

  const keywords = {
    name_pt: '',
    name_en: '',
    name_es: ''
  }
  _keywords ||= []
  for (const keyword of _keywords) {
    keywords.name_pt = (keywords.name_pt ? keywords.name_pt + ', ' : '') + keyword.name_pt
    keywords.name_en = (keywords.name_en ? keywords.name_en + ', ' : '') + keyword.name_en
    keywords.name_es = (keywords.name_es ? keywords.name_es + ', ' : '') + keyword.name_es
  }

  return <>
    {Boolean(_keywords.length) && Translate(keywords, 'name', keywords => <Helmet><meta name="keywords" content={keywords} /></Helmet>)}
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <title>{title}</title>
      <link rel="canonical" href={`https://${process.env.GATSBY_URL}${path}`} />
      {!yoast_head && MetaNodes(path, image)}
      {yoastNodes}
      {scriptNodes.map((node, key) => <script key={key} {...node.attribs}>{node.children[0].data}</script>)}
      <script src="//cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.7/ScrollMagic.min.js"></script>
      <script src="/plugins/scrollmagic.js"></script>
      <link rel="apple-touch-icon" sizes="48x48" href={icon48x48} />
      <link rel="apple-touch-icon" sizes="48x48" href={icon72x72} />
      <link rel="apple-touch-icon" sizes="48x48" href={icon96x96} />
      <link rel="apple-touch-icon" sizes="48x48" href={icon144x144} />
      <link rel="apple-touch-icon" sizes="48x48" href={icon192x192} />
      <link rel="apple-touch-icon" sizes="48x48" href={icon256x256} />
      <link rel="apple-touch-icon" sizes="48x48" href={icon384x384} />
      <link rel="apple-touch-icon" sizes="48x48" href={icon512x512} />
      <link rel="shortcut icon" href={favicon} />
      <meta http-equiv="Cache-Control" content="no-cache" />
      <meta http-equiv="Pragma" content="no-cache" />
      <meta http-equiv="Expires" content="0" />
    </Helmet>
  </>
}