import axios from 'axios'
const main = axios.create()

const requestData = (method, path, data = null, config = {}) => {
  const apiUrl = `https://admin.rotesma.com.py/api/${path}`;
  return main[method](apiUrl, data, config)
    .then(response => response.data)
    .catch(error => {
      if (error.response && error.response.data) {
        return error.response.data
      }
      return { errors: {} }
    })
}

export const requestBlogData = (method, path, data = null, config = {}) => {
  const apiUrl = `https://blog.rotesma.com.br/wp-json/wp/v2/${path}`;
  return main[method](apiUrl, data, config)
    .then(response => response.data)
    .catch(error => {
      if (error.response && error.response.data) {
        return error.response.data
      }
      return { errors: {} }
    })
}

export const requestBlog = (method, path, data = null, config = {}) => {
  const apiUrl = `https://blog.rotesma.com.br/wp-json/wp/v2/${path}`;
  return main[method](apiUrl, data, config)
    .then(response => response)
    .catch(error => {
      if (error.response && error.response.data) {
        return error.response.data
      }
      return { errors: {} }
    })
}

export default requestData
