import React, { useContext, useEffect, useState } from 'react'
import { LoadingContext } from '@layouts'
import ReactPaginate from 'react-js-pagination'
import { BlogAside, Button, Grid, Head, Link, Translate } from '@components'
import { requestBlog } from '@api'
import locale from '@data/locales/blog'
import * as st from '@assets/styl/Blog.module.styl'

type Post = {
  title: string,
  image: string,
  author: string,
  date: Date,
  excerpt: string,
  slug: string
}

export default ({ location: { pathname }, query }) => {
  const [posts, setPosts] = useState<Array<Post>>([])
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)
  useEffect(() => {
    toggleLoading(true)
    if (query)
      requestBlog('get', `search?search=${query}&subtype=post&_embed&per_page=4&page=${page+1}`)
      .then(res => {
        setPageCount(res.headers['x-wp-totalpages'])
        const posts = res.data.map(post => {
          const { title, _embedded: { self: [ { date, excerpt: { rendered: excerpt }, _links: _embedded, slug } ] } } = post
          return {
            title,
            author: _embedded.author[0] && _embedded.author[0].name,
            date: new Date(date),
            image_url: _embedded['wp:featuredmedia'][0] && _embedded['wp:featuredmedia'][0].href,
            excerpt,
            slug
          }
        })
        setPosts(posts)
        const promises = posts.map((post) => requestBlog('get', 'posts?_embed&slug=' + post.slug).then(res => res.data[0]).then((postData) => {
          const { _embedded } = postData
          post.author = _embedded.author[0] && _embedded.author[0].name
          post.image = _embedded['wp:featuredmedia'][0] && _embedded['wp:featuredmedia'][0].source_url
        }))
        Promise.all(promises).then(() => {
          setPosts([...posts])
          toggleLoading(false)
        })
      })
    else
      requestBlog('get', `posts?_embed&per_page=4&page=${page+1}`)
        .then(res => {
          setPageCount(res.headers['x-wp-totalpages'])
          setPosts(res.data.map(post => {
            const { date, title: { rendered: title }, excerpt: { rendered: excerpt }, _embedded, slug } = post
            return {
              title,
              author: _embedded.author[0] && _embedded.author[0].name,
              date: new Date(date),
              image: _embedded['wp:featuredmedia'][0] && _embedded['wp:featuredmedia'][0].source_url,
              excerpt,
              slug
            }
          }))
          toggleLoading(false)
        })
  }, [page, query])
  const [loading, setLoading] = useState(true)
  const { toggleVisible: _toggleLoading } = useContext(LoadingContext)
  const toggleLoading = (value: boolean) => {
    setLoading(value)
    _toggleLoading(value)
  }

  return <>
    {Translate(locale, 'title', title => <Head title={title} path={pathname} />)}

    {Boolean(posts.length || !loading) && <Grid wide className={st.core}>

      <section className={st.posts}>
        {posts.map(({ title, image, date, author, excerpt, slug }, key) => <article key={key}>
          <div className={st.thumbnail} style={image ? { backgroundImage: `url(${image})` } : undefined}></div>
          <Link to={slug} prefix="blog"><h4>{title}</h4></Link>
          <span>{`${new Date(date).toLocaleDateString()}`} {author && <> | {Translate(locale, 'by')} {author}</>}</span>
          <div className={st.content} dangerouslySetInnerHTML={{ __html: excerpt }}></div>
          <Button to={slug} prefix="blog" className={st.btn}>Saiba mais</Button>
        </article>)}

        {!Boolean(posts.length) && <p>{Translate(locale, 'not_found')} "{query || ''}".</p>}

        {pageCount > 1 && <ReactPaginate
          itemsCountPerPage={4}
          totalItemsCount={4 * pageCount}
          activePage={page + 1}
          pageRangeDisplayed={5}
          prevPageText=""
          nextPageText=""
          onChange={(page) => setPage(page - 1)}
          innerClass={st.pages}
        />}
      </section>

      <BlogAside />
    </Grid>}
  </>
}