export default [
  {
    name_pt: 'Segmentos',
    name_en: 'Segments',
    name_es: 'Áreas',
    segments: true
  },
  {
    name_pt: 'Sobre a Rotesma',
    name_en: 'About Rotesma',
    name_es: 'Sobre Rotesma',
    to_pt: 'sobre',
    to_en: 'about',
    to_es: 'sobre',
    showOnFooter: true
  },
  {
    name_pt: 'Tecnologia',
    name_en: 'Technology',
    name_es: 'Tecnología',
    to_pt: 'tecnologia',
    to_en: 'technology',
    to_es: 'tecnologia',
    showOnFooter: true
  },
  {
    name_pt: 'Blog',
    name_en: 'Blog',
    name_es: 'Blog',
    to_pt: 'blog',
    to_en: 'blog',
    to_es: 'blog',
    showOnFooter: true
  },
  {
    name_pt: 'Solicite um orçamento',
    name_en: 'Request a quote',
    name_es: 'Solicitar presupuesto',
    to_pt: 'orcamento',
    to_en: 'request-a-quote',
    to_es: 'presupuesto',
    className: 'btn'
  }
]