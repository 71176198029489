// extracted by mini-css-extract-plugin
export var authorBox = "Blog-module--author-box--b1185";
export var avatar = "Blog-module--avatar--41f6d";
export var btn = "Blog-module--btn--c2b85";
export var ccontent = "Blog-module--ccontent--7c4a3";
export var commentForm = "Blog-module--comment-form--74b13";
export var comments = "Blog-module--comments--60170";
export var content = "Blog-module--content--09df4";
export var core = "Blog-module--core--7e8a5";
export var error = "Blog-module--error--f4c36";
export var pages = "Blog-module--pages--59941";
export var post = "Blog-module--post--0b0a7";
export var posts = "Blog-module--posts--136a1";
export var reply = "Blog-module--reply--e1e02";
export var thumbnail = "Blog-module--thumbnail--5a6f4";