import React from 'react'
import { Link } from 'gatsby'

export default {
  unit_pt: 'Unidade',
  unit_en: 'Unit',
  unit_es: 'Unidad',
  name_pt: 'Nome',
  name_en: 'Name',
  name_es: 'Nombre',
  birthday_pt: 'Data de nascimento',
  birthday_en: 'Birthday',
  birthday_es: 'Fecha de Nacimiento',
  gender_pt: 'Sexo',
  gender_en: 'Sex',
  gender_es: 'Sexo',
  cpf_pt: 'CPF',
  cpf_en: 'CPF',
  cpf_es: null,
  rg_pt: 'RG',
  rg_en: 'RG',
  rg_es: 'CI',
  rg_mask_pt: '9.999.999',
  rg_mask_en: '9.999.999',
  rg_mask_es: null,
  schooling_pt: 'Escolaridade',
  schooling_en: 'Education',
  schooling_es: 'Escolaridad',
  area_pt: 'Área de interesse',
  area_en: 'Área de interesse',
  area_es: 'Áreas de Interés',
  address_pt: 'Endereço',
  address_en: 'Address',
  address_es: 'Dirección',
  neighborhood_pt: 'Bairro',
  neighborhood_en: 'Neighborhood',
  neighborhood_es: 'Barrio',
  city_pt: 'Cidade',
  city_en: 'City',
  city_es: 'Ciudad',
  uf_pt: 'UF',
  uf_en: 'State',
  uf_es: 'Departamento',
  phone_pt: 'Telefone',
  phone_en: 'Phone',
  phone_es: 'Teléfono',
  email_pt: 'E-mail',
  email_en: 'Email',
  email_es: 'E-mail',
  file_pt: 'Anexar arquivo',
  file_en: 'Attach file',
  file_es: 'Adjuntar archivo',
  send_pt: 'Enviar',
  send_en: 'Send',
  send_es: 'Enviar',
  company_pt: 'Empresa',
  company_en: 'Company',
  company_es: 'Empresa',
  size_pt: 'M² da obra',
  size_en: 'M² da obra',
  size_es: 'M² da obra',
  file_project_pt: 'Anexar pré-projeto',
  file_project_en: 'Anexar pré-projeto',
  file_project_es: 'Adjuntar anteproyecto',
  privacy_pt: <span>Declaro que li e aceito a <Link to="/politica-de-privacidade/">Política de Privacidade</Link> da Rotesma.</span>,
  privacy_en: <span>Declaro que li e aceito a <Link to="/privacy-policy/">Política de Privacidade</Link> da Rotesma.</span>,
  privacy_es: <span>Declaro que he leído y acepto la <Link to="/politica-de-privacidad/">Política de Privacidad</Link> de Rotesma .</span>,
  comment_pt: 'Comentário',
  comment_en: 'Comment',
  comment_es: 'Comentario'
}
