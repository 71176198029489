export default {
  head_title_pt: 'Rotesma - Trabalhe conosco',
  head_title_en: 'Rotesma - Work with us',
  head_title_es: 'Rotesma - Trabaja con Nosotros',
  title_pt: 'Trabalhe conosco',
  title_en: 'Work with us',
  title_es: 'Trabaja con nosotros',
  description_pt: 'Fique atento as novas oportunidades, preencha o formulário e faça parte da Rotesma.',
  description_en: 'Fique atento as novas oportunidades, preencha o formulário e faça parte da Rotesma.',
  description_es: 'Mantente atento a nuevas oportunidades, llena el formulario y sé parte de Rotesma.'
}
