export default {
  '/': ['/', '/', '/'],
  '/en/': ['/', '/', '/'],
  '/es/': ['/', '/', '/'],

  '/sobre/': ['/sobre/', '/sobre/', '/about/'],
  '/en/about/': ['/sobre/', '/sobre/', '/about/'],
  '/es/sobre/': ['/sobre/', '/sobre/', '/about/'],
  
  '/tecnologia/': ['/tecnologia/', '/tecnologia/', '/technology/'],
  '/en/technology/': ['/tecnologia/', '/tecnologia/', '/technology/'],
  '/es/tecnologia/': ['/tecnologia/', '/tecnologia/', '/technology/'],
  
  '/trabalhe-conosco/': ['/trabalhe-conosco/', '/trabaja-con-nosotros/', '/work-with-us/'],
  '/en/work-with-us/': ['/trabalhe-conosco/', '/trabaja-con-nosotros/', '/work-with-us/'],
  '/es/trabaja-con-nosotros/': ['/trabalhe-conosco/', '/trabaja-con-nosotros/', '/work-with-us/'],
  
  '/orcamento/': ['/orcamento/', '/presupuesto/', '/request-a-quote/'],
  '/es/presupuesto/': ['/orcamento/', '/presupuesto/', '/request-a-quote/'],
  '/en/request-a-quote/': ['/orcamento/', '/presupuesto/', '/request-a-quote/'],

  '/blog/': ['/blog/', '/blog/', '/blog/'],
  '/es/blog/': ['/blog/', '/blog/', '/blog/'],
  '/en/blog/': ['/blog/', '/blog/', '/blog/'],

  '/404/': ['/404/', '/404/', '/404/'],
  '/es/404/': ['/404/', '/404/', '/404/'],
  '/en/404/': ['/404/', '/404/', '/404/'],

  '/obrigado/': ['/obrigado/', '/gracias/', '/thank-you/'],
  '/es/gracias/': ['/obrigado/', '/gracias/', '/thank-you/'],
  '/en/thank-you/': ['/obrigado/', '/gracias/', '/thank-you/'],

  '/politica-de-privacidade/': ['/politica-de-privacidade/', '/politica-de-privacidad/', '/privacy-policy/'],
  '/es/politica-de-privacidad/': ['/politica-de-privacidade/', '/politica-de-privacidad/', '/privacy-policy/'],
  '/en/privacy-policy/': ['/politica-de-privacidade/', '/politica-de-privacidad/', '/privacy-policy/']
}