import React, { Fragment, useContext, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { LoadingContext, LocationContext } from '@layouts'
import { getLanguage } from '@components/Translation'
import { ApiHook, Button, Footer, Grid, Head, Translate } from '@components'
import { graphql, useStaticQuery } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'
import _InputMask from 'react-input-mask'
import locale from '@data/locales/work-with-us'
import formsLocale from '@data/locales/forms'
import api from '@api'
import cn from 'classnames'
import * as st from '@assets/styl/WorkWithUs.module.styl'

export const Input = ({ name, required = true, ...props }: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) =>
  Translate(formsLocale, name, placeholder => <input name={name} placeholder={placeholder} required={required} {...props} />)

export const InputMask = ({ mask, name, required = true, value, onChange, ...props }: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { mask: string }) =>
  Translate(formsLocale, name, placeholder => 
    placeholder && <_InputMask mask={mask} maskChar={null} value={value} onChange={onChange}>
      {(inputProps) => <input name={name} placeholder={placeholder} required={required} {...inputProps} {...props} />}
    </_InputMask>
  )

export const Select = ({ name, children, required = true, ...props }: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) =>
  <select name={name} {...props} required={required}>
    {Translate(formsLocale, name, placeholder => <option value="" hidden disabled>{placeholder}</option>)}
    {(children as Array<any>).map((opt, key) => <Fragment key={key}>{Translate(opt, 'name', str => <option value={opt.value}>{str}</option>)}</Fragment>)}
  </select>

type FileProps = {
  value: File,
  name: string,
  onChange: (e) => void,
  placeholder?: string,
  inputRef: React.MutableRefObject<HTMLInputElement>,
  required?: boolean,
  className: string
}

export const File = ({ value, name, onChange, placeholder, inputRef: ref, required = true, className }: FileProps) =>
  <div className={className} onClick={() => ref.current.click()} onDragOver={(e) => e.preventDefault()} onDrop={(e) => {
    e.preventDefault()
    if (e.dataTransfer.items) {
      for (let i=0; i<e.dataTransfer.items.length; i++)
        if (e.dataTransfer.items[i].kind === 'file') {
          onChange({ target: { name, value: e.dataTransfer.items[i].getAsFile() } })
        }
    } else
      for (let i=0; i<e.dataTransfer.files.length; i++) 
        onChange({ target: { name, value: e.dataTransfer.files[i] } })
  }}>
    {value?.name || Translate(formsLocale, placeholder || name)} <span className="icon-file"></span>
    <input type="file" onChange={(e) => onChange({ target: { name, value: e.target.files[0] } })} ref={ref} required={required && !value} />
  </div>

type CheckboxProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  children: React.ReactNode
}

export const Checkbox = ({ children: label, className, required = true, ...props }: CheckboxProps) =>
  <div className={className}>
    <input {...props} type="checkbox" required={required} />
    <div>{label}</div>
  </div>

type RecaptchaProps = {
  className?: string,
  inputRef: React.MutableRefObject<ReCAPTCHA>
}

export const Recaptcha = ({ inputRef: ref, className }: RecaptchaProps) =>
  <ReCAPTCHA ref={ref} sitekey="6LfjIyYfAAAAAHMLfvsIpQ3R3-GbNJyhUlcFrMJ6" size="invisible" className={className} />

export default ({ location: { pathname } }) => {
  const query = useStaticQuery(graphql`
    query {
      allAddresses {
        nodes {
          name_pt
          name_en
          name_es
        }
      }
      allAreas {
        nodes {
          area_id
          name_pt
          name_en
          name_es
        }
      }
    }
  `)
  const addresses = ApiHook(query.allAddresses.nodes, 'addresses')
  const areas = ApiHook(query.allAreas.nodes, 'areas?max=9999')

  const [data, setData] = useState({
    unit: '',
    name: '',
    birthday: '',
    gender: '',
    cpf: '',
    rg: '',
    schooling: '',
    area: '',
    address: '',
    neighborhood: '',
    city: '',
    uf: '',
    phone: '',
    email: '',
    file: null,
    privacy: false
  })
  const [error, setError] = useState('')
  const fileRef = useRef<HTMLInputElement>()
  const recaptchaRef = useRef<ReCAPTCHA>()
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const location = useContext(LocationContext)
  const language = getLanguage(location)
  const success = () => {
    if (language === 'es')
      navigate('/gracias/')
    else if (language === 'en')
      navigate('/thank-you/')
    else
      navigate('/obrigado/')
  }
  const submit = (recaptcha: string) => {
    toggleLoading(true)
    const birthDate = data.birthday.split('/')
    const _data = { ...data, birthday: `${birthDate[2]}-${birthDate[1]}-${birthDate[0]}`, recaptcha }
    if (language === 'es')
      delete _data.cpf
    const formData = new FormData()
    for (const key in _data)
      formData.append(key, _data[key])
    api('post', 'leads/work-with-us', formData)
      .then(data => {
        if (data.code === 0)
          success()
        else {
          recaptchaRef.current.reset()
          let error = ''
          for (const key in data.errors) {
            error = data.errors[key]
            break
          }
          if (!error) error = 'Data de nascimento inválida'
          setError(error)
          toggleLoading(false)
        }
      })
  }
  const { toggleVisible: toggleLoading } = useContext(LoadingContext)

  return <>
    {Translate(locale, 'head_title', title => <Head title={title} path={pathname} />)}

    <section className={st.core} id="form-section">
      <Grid>
        <div className={st.wrapper}>
          <h1>{Translate(locale, 'title')}</h1>
          <p>{Translate(locale, 'description')}</p>
          <form onSubmit={(e) => { e.preventDefault(); recaptchaRef.current.executeAsync().then((value) => submit(value)) }}>
            <Select name="unit" value={data.unit} onChange={onChange}>{addresses}</Select>
            <Input name="name" value={data.name} onChange={onChange} />
            <InputMask mask="99/99/9999" value={data.birthday} onChange={onChange} name="birthday" pattern=".{10,10}" className={st.left} />
            <Select name="gender" value={data.gender} onChange={onChange}>{[
              { name_pt: 'Feminino', name_en: 'Feminino', name_es: 'Feminino' },
              { name_pt: 'Masculino', name_en: 'Masculino', name_es: 'Masculino' }
            ]}</Select>
            <div className={st.clear}></div>
            <InputMask mask="999.999.999-99" value={data.cpf} onChange={onChange} name="cpf" pattern=".{14,14}" className={st.left} />
            {Translate(formsLocale, 'rg_mask', mask => mask ?
              <InputMask mask={mask} value={data.rg} onChange={onChange} name="rg" pattern=".{9,9}" /> :
              <Input value={data.rg} onChange={onChange} name="rg" />
            )}
            <Input name="schooling" className={st.clear} value={data.schooling} onChange={onChange} />
            <Select name="area" value={data.area} onChange={onChange}>{areas.map(a => ({ value: a.area_id || a.id, ...a }))}</Select>
            <Input name="address" className={st.left} value={data.address} onChange={onChange} />
            <Input name="neighborhood" value={data.neighborhood} onChange={onChange} />
            <Input name="city" className={st.left} value={data.city} onChange={onChange} />
            <Input name="uf" value={data.uf} onChange={onChange} />
            <div className={st.clear}></div>
            <InputMask mask={language === 'es' ? '999 999999' : data.phone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999'} value={data.phone} onChange={onChange} name="phone" type="tel" pattern={language === 'es' ? '.{9,10}' : '.{14,15}'} className={st.left} />
            <Input name="email" type="email" value={data.email} onChange={onChange} />
            <File name="file" value={data.file} inputRef={fileRef} onChange={onChange} className={cn(st.file, st.clear)} required={false} />
            <Checkbox name="privacy" onChange={(e) => onChange({ ...e, value: e.target.checked })} className={st.checkbox}>{Translate(formsLocale, 'privacy')}</Checkbox>
            {error && <div className={st.error}>{error}</div>}
            <div>
              <Recaptcha inputRef={recaptchaRef} className={st.left} />
              <Button className={st.btn}>{Translate(formsLocale, 'send') as unknown as string}</Button>
            </div>
            <div className={st.clear}></div>
          </form>
        </div>
      </Grid>
    </section>

    <Footer />
  </>
}