import React from 'react'
import { getLanguage } from '@components/Translation'
import { LocationContext } from '@layouts'
import { Link } from 'gatsby'

const prefixes = {
  segment: {
    pt: '/segmento/',
    en: '/segment/',
    es: '/area/'
  },
  construction: {
    pt: '/obra/',
    en: '/construction/',
    es: '/construccion/'
  },
  blog: {
    pt: '/blog/',
    en: '/blog/',
    es: '/blog/'
  },
  '': {
    pt: '/',
    en: '/',
    es: '/'
  }
}

const suffix = '/'

export default ({ children, prefix = '', noFix = false, to, ...props }: any) =>
  noFix ? <Link to={to} {...props}>{children}</Link> :
  <LocationContext.Consumer>
    {location => {
      const lang = location && getLanguage(location) || 'pt'
      return <Link to={prefixes[prefix][lang] + to + (to ? suffix : '')} {...props}>{children}</Link>
    }}
  </LocationContext.Consumer>