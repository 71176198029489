import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { LoadingContext } from '@layouts'
import cn from 'classnames'
import * as st from '@assets/styl/Loading.module.styl'

type LoadingProps = {
  visible: boolean
}

export default ({ visible }: LoadingProps) => <div className={cn(st.core, visible && st.visible)}>
  <CircularProgress style={{ color: '#E3281B' }} size={25} />
</div>