import React, { useContext, useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { LoadingContext, LocationContext } from '@layouts'
import { getLanguage } from '@components/Translation'
import { Link, Translate } from '@components'
import formsLocale from '@data/locales/forms'
import ReCAPTCHA from 'react-google-recaptcha'
import { Checkbox, Recaptcha } from '@pages/trabalhe-conosco'
import api, { requestBlogData } from '@api'
import locale from '@data/locales/blog'
import * as st from '@assets/styl/BlogAside.module.styl'

export default () => {
  const [posts, setPosts] = useState([])
  useEffect(() => {
    requestBlogData('get', 'posts?_embed&per_page=3')
      .then(posts => setPosts(posts.map(post => {
        const { title: { rendered: title }, slug, _embedded } = post
        return {
          title, slug,
          image: _embedded['wp:featuredmedia'][0] && _embedded['wp:featuredmedia'][0].source_url
        }
      })))
  }, [])
  const location = useContext(LocationContext)
  const [query, setQuery] = useState('')
  const search = () => {
    if (!query) return
    const language = getLanguage(location)
    if (language === 'es')
      navigate(`/blog/b/${query}/`)
    else if (language === 'en')
      navigate(`/blog/s/${query}/`)
    else
      navigate(`/blog/p/${query}/`)
  }
  const recaptchaRef = useRef<ReCAPTCHA>()

  const [data, setData] = useState({
    privacy: false,
    email: '',
    recaptcha: ''
  })
  const success = () => {
    const language = getLanguage(location)
    if (language === 'es')
      navigate('/gracias/')
    else if (language === 'en')
      navigate('/thank-you/')
    else
      navigate('/obrigado/')
  }
  const submit = (recaptcha: string) => {
    toggleLoading(true)
    api('post', 'leads/subscriber', { ...data, recaptcha }, { headers: { 'Content-Type': 'text/plain' } })
      .then(data => {
        if (data.code === 0)
          success()
        else {
          recaptchaRef.current.reset()
          toggleLoading(false)
        }
      })
  }
  const { toggleVisible: toggleLoading } = useContext(LoadingContext)

  return <aside className={st.core}>
    <form className={st.search} onSubmit={(e) => { e.preventDefault(); search() }}>
      {Translate(locale, 'search', placeholder => <input placeholder={placeholder} value={query} onChange={(e) => setQuery(e.target.value)} />)}
    </form>
    {Boolean(posts.length) && <div className={st.recent}>
      <h3>{Translate(locale, 'recent')}</h3>
      <ul>
        {posts.map(({ title, slug, image }, key) => <li key={key}><Link to={slug} prefix="blog">
          <div style={{ backgroundImage: `url(${image})` }} className={st.thumbnail}></div>
          <h4>{title.slice(0, 40)}...</h4>
        </Link></li>)}
      </ul>
    </div>}
    <div className={st.newsletter}>
      <h3>{Translate(locale, 'newsletter')}</h3>
      <form onSubmit={(e) => { e.preventDefault(); recaptchaRef.current.executeAsync().then((value) => submit(value)) }}>
        <div>
          {Translate(locale, 'email', placeholder => <input type="email" placeholder={placeholder} onChange={(e) => setData({ ...data, email: e.target.value })} required />)}
          <Recaptcha inputRef={recaptchaRef} />
          <button></button>
        </div>
        <Checkbox name="privacy" onChange={(e) => setData({ ...data, privacy: e.target.checked })} className={st.checkbox} required>{Translate(formsLocale, 'privacy')}</Checkbox>
      </form>
    </div>
  </aside>
}