import React, { useContext, useEffect, useState } from 'react'
import { LoadingContext, LocationContext } from '@layouts'
import { getLanguage } from '@components/Translation'
import { scroller } from 'react-scroll'
import Lightbox from 'lightbox-react'
import { navigate } from 'gatsby'
import { BlogAside, Button, Grid, Head, Translate } from '@components'
import { requestBlogData } from '@api'
import locale from '@data/locales/blog'
import formsLocale from '@data/locales/forms'
import cn from 'classnames'
import * as st from '@assets/styl/Blog.module.styl'

type Context = {
  id?: number,
  title: string,
  image: string,
  author: string,
  author_avatar?: string,
  author_description?: string,
  date: Date,
  content: string,
  comment_status?: string,
  comments?: Array<{
    id: number,
    author: string,
    avatar: string,
    date: Date,
    content: string,
    parent?: number
  }>,
  yoast_head?: string
}

export default ({ location: { pathname }, pageContext, slug }) => {
  const [context, setContext] = useState<Context>(pageContext)
  useEffect(() => {
    requestBlogData('get', 'posts?_embed&slug=' + slug)
      .then(posts => posts[0])
      .then(async post => {
        if (post) {
          const { id, date, title: { rendered: title }, content: { rendered: content }, _embedded, comment_status, yoast_head } = post
          const comments = (await requestBlogData('get', 'comments?post=' + id) || [])
            .filter(({ status }) => status === 'approved')
            .map(({ id, author_name, date, content: { rendered: content }, author_avatar_urls, parent }) => ({
              id,
              author: author_name,
              avatar: author_avatar_urls[96],
              date: new Date(date),
              content,
              parent
            }))
          setContext({
            id,
            title,
            author: _embedded.author[0] && _embedded.author[0].name,
            author_avatar: _embedded.author[0] && _embedded.author[0].avatar_urls[96],
            author_description: _embedded.author[0] && _embedded.author[0].description,
            date: new Date(date),
            image: _embedded['wp:featuredmedia'][0] && _embedded['wp:featuredmedia'][0].source_url,
            content,
            comment_status,
            comments,
            yoast_head
          })
          toggleLoading(false)
        } else error()
      })

    // Zoom de galeria
    const zoom = (e: MouseEvent) => {
      const src = ((e.currentTarget as HTMLElement).querySelector('img') as HTMLImageElement).src
      const images = Array.from(document.querySelectorAll('.blocks-gallery-grid img')).map((img: HTMLImageElement) => img.src)
      const index = images.indexOf(src)
      setZoomObj({ index, items: images })
    }
    document.querySelectorAll('.blocks-gallery-grid figure').forEach((galleryItem) => galleryItem.addEventListener('click', zoom))
  }, [])

  const [zoomObj, setZoomObj] = useState<{ index: number, items?: Array<string> }>({ index: 0 })

  const location = useContext(LocationContext)
  const success = () => {
    const language = getLanguage(location)
    if (language === 'es')
      navigate('/gracias/')
    else if (language === 'en')
      navigate('/thank-you/')
    else
      navigate('/obrigado/')
  }
  const error = () => {
    navigate('/404/')
  }
  const { toggleVisible: toggleLoading } = useContext(LoadingContext)
  !context.title && toggleLoading(true)
  const [data, setData] = useState({
    name: '',
    email: '',
    content: '',
    parent: undefined
  })
  const [errorMessage, setErrorMessage] = useState('')
  const submitComment = (e: React.FormEvent) => {
    e.preventDefault()
    toggleLoading(true)
    requestBlogData('post', `comments?author_email=${data.email}&author_name=${data.name}&content=${data.content}&post=${context.id}`)
      .then((data) => {
        if (data.status)
          success()
        else {
          setErrorMessage(data.message)
          toggleLoading(false)
        }
      })
  }

  return <>
    <Head title={context.title} path={pathname} yoast_head={context.yoast_head} />

    {context.title && <Grid wide className={st.core}>

      <section className={st.post}>
        <div className={st.thumbnail} style={{ backgroundImage: `url(${context.image})` }}></div>
        <h1>{context.title}</h1>
        <span>{`${new Date(context.date).toLocaleDateString()}`} | {Translate(locale, 'by')} {context.author}</span>
        <div className={st.content} dangerouslySetInnerHTML={{ __html: context.content }}></div>

        {context.author_description && <div className={st.authorBox}>
          <div className={st.avatar} style={{ backgroundImage: `url(${context.author_avatar})` }}></div>
          <div>
            <h3>{Translate(locale, 'written_by')} {context.author}</h3>
            <p>{context.author_description}</p>
          </div>
        </div>}

        {Boolean(context.comments?.length) && <>
          <h3>{context.comments.length} {Translate(locale, 'comment')}{Boolean(context.comments.length - 1) && 's'}</h3>
          <ul className={st.comments}>
            {context.comments.map((comment, key) => <li key={key} className={cn(comment.parent && st.reply)}>
              <div className={st.avatar} style={{ backgroundImage: `url(${comment.avatar})` }}></div>
              <div>
                <h4>{comment.author}</h4>
                <span>{new Date(comment.date).toLocaleDateString()}</span>
                <div className={st.ccontent} dangerouslySetInnerHTML={{ __html: comment.content }}></div>
                {context.comment_status === 'open' && <button onClick={() => { setData({ ...data, parent: comment.id }); scroller.scrollTo('comment') }}>{Translate(locale, 'reply')}</button>}
              </div>
            </li>)}
          </ul>
        </>}

        {context.comment_status === 'open' && <div id="comment" className={st.commentForm}>
          <h3>{data.parent ? <>{Translate(locale, 'reply')} <button onClick={() => setData({ ...data, parent: undefined })}>Cancelar</button></> : Translate(locale, 'add_comment')}</h3>
          <form onSubmit={submitComment}>
            {Translate(formsLocale, 'name', placeholder => <input placeholder={placeholder} value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} required />)}
            {Translate(formsLocale, 'email', placeholder => <input placeholder={placeholder} type="email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} required />)}
            {Translate(formsLocale, 'comment', placeholder => <textarea placeholder={placeholder} value={data.content} onChange={(e) => setData({ ...data, content: e.target.value })} required></textarea>)}
            <Button className={st.btn}>{Translate(locale, 'send_comment') as unknown as string}</Button>
            {errorMessage && <div className={st.error}>{errorMessage}</div>}
          </form>
        </div>}
      </section>

      <BlogAside />
    </Grid>}

    {zoomObj.items && <Lightbox mainSrc={zoomObj.items[zoomObj.index]} onCloseRequest={() => setZoomObj({ index: 0 })}
      prevSrc={zoomObj.items[(zoomObj.index + zoomObj.items.length - 1) % zoomObj.items.length]}
      nextSrc={zoomObj.items[(zoomObj.index + 1) % zoomObj.items.length]}
      onMovePrevRequest={() => setZoomObj({ ...zoomObj, index: (zoomObj.index + zoomObj.items.length - 1) % zoomObj.items.length })}
      onMoveNextRequest={() => setZoomObj({ ...zoomObj, index: (zoomObj.index + 1) % zoomObj.items.length })} />}
  </>
}