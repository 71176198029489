export default {
  title_pt: 'Rotesma - Blog',
  title_en: 'Rotesma - Blog',
  title_es: 'Rotesma - Blog',
  search_pt: 'Buscar...',
  search_en: 'Buscar...',
  search_es: 'Búsqueda ...',
  recent_pt: 'Posts recentes',
  recent_en: 'Posts recentes',
  recent_es: 'Posts recientes ',
  newsletter_pt: 'Fique ligado',
  newsletter_en: 'Fique ligado',
  newsletter_es: 'Quedate atento',
  email_pt: 'Seu e-mail',
  email_en: 'Seu e-mail',
  email_es: 'E-mail',
  name_pt: 'Nome',
  name_en: 'Nome',
  name_es: 'Nombre',
  comment_pt: 'Comentário',
  comment_en: 'Comentário',
  comment_es: 'Comentario',
  by_pt: 'Por',
  by_en: 'Por',
  by_es: 'Por',
  written_by_pt: 'Escrito por',
  written_by_en: 'Escrito por',
  written_by_es: 'Escrito por',
  add_comment_pt: 'Adicione comentário',
  add_comment_en: 'Adicione comentário',
  add_comment_es: 'Agregar comentario',
  send_comment_pt: 'Enviar comentário',
  send_comment_en: 'Enviar comentário',
  send_comment_es: 'Enviar comentario ',
  not_found_pt: 'Nenhuma publicação encontrada para a busca',
  not_found_en: 'Nenhuma publicação encontrada para a busca',
  not_found_es: 'No se encontraron publicaciones para la búsqueda',
  reply_pt: 'Responder',
  reply_en: 'Responder',
  reply_es: 'Respuesta'
}
